// LongPress.jsx
import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { NULL_FUNCTION } from '../resource/defaults.js';

const LONG_PRESS_STYLE = {
  WebkitTouchCallout: 'none',
  userSelect: 'none',
  userDrag: 'none',
};

const defaultOptions = {
  shouldPreventDefault: false,
  delay: 1000,
};

export const LongPress = ({
  onLongPress = NULL_FUNCTION,
  onClick = NULL_FUNCTION,
  options = defaultOptions,
  children = null,
}) => {
  const longPressEvent = useLongPress({
    onLongPress,
    onClick,
    options,
  });

  return React.cloneElement(children, {
    style: LONG_PRESS_STYLE,
    ...longPressEvent,
  });
};

LongPress.propTypes = {
  children: PropTypes.node.isRequired,
  onLongPress: PropTypes.func,
  onClick: PropTypes.func,
  options: PropTypes.shape({
    shouldPreventDefault: PropTypes.bool,
    delay: PropTypes.number,
  }),
};

export const useLongPress = ({
  onLongPress,
  onClick,
  options: { shouldPreventDefault = true, delay = 300 } = {},
}) => {
  const [longPressTriggered, setLongPressTriggered] = useState(false);
  const timeout = useRef();
  const target = useRef();

  const start = useCallback(
    event => {
      if (
        (event.touches && event.touches.length > 1) ||
        window.visualViewport?.scale > 1
      )
        return;

      if (shouldPreventDefault && event.target) {
        event.target.addEventListener('touchend', preventDefault, {
          passive: false,
        });
        target.current = event.target;
      }
      timeout.current = setTimeout(() => {
        onLongPress(event);
        setLongPressTriggered(true);
      }, delay);
    },
    [onLongPress, delay, shouldPreventDefault]
  );

  const clear = useCallback(
    (event, shouldTriggerClick = true) => {
      timeout.current && clearTimeout(timeout.current);
      shouldTriggerClick && !longPressTriggered && onClick && onClick();
      setLongPressTriggered(false);
      if (shouldPreventDefault && target.current) {
        target.current.removeEventListener('touchend', preventDefault);
      }
    },
    [shouldPreventDefault, onClick, longPressTriggered]
  );

  return {
    onMouseDown: e => start(e),
    onTouchStart: e => start(e),
    onMouseUp: e => clear(e),
    onMouseLeave: e => clear(e, false),
    onTouchEnd: e => clear(e),
  };
};

const isTouchEvent = event => {
  return 'touches' in event;
};

const preventDefault = event => {
  if (!isTouchEvent(event)) return;

  if (event.touches.length < 2 && event.preventDefault) {
    event.preventDefault();
  }
};

export default LongPress;
